import React from "react"
import Image from "gatsby-image"

import Layout from "../layouts/layout"
import SEO from "../components/seo"

const PrivacyPage = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`;
    return(
        <Layout location={location} title={siteTitle}>
            <section className="uni-section public-container">
                <div className="uni-container">
                    <div>
                        <h2>Privacy Policy</h2>
                        <p className="pp-p">
                            Sunset Health, Inc. (“we”) is committed to protecting and respecting your privacy. This Privacy Policy (“Policy”) 
                            (together with our Terms and any other documents referred to on it) sets out the basis on which any personal data we 
                            collect from you, or that you provide to us, will be processed by us and how you can get access to this information. 
                            If in doubt, the primary governing law of this policy is that of the state of California, United States.
                        </p>
                    </div>
                    <div>
                        <h4 className="emp-h4 emp-font-semibold">1. Purpose of this Policy</h4>
                        <p className="pp-p">
                            Sunset Health provides you (the “User”) with access to the online and mobile services associated with Sunset Health, 
                            including but not limited to, sunset.health and all associated subdomains (the “Website”), and the Sunset Health mobile 
                            application (the “App”), collectively the “System”.
                        </p>
                        <h4 className="emp-h4 emp-font-semibold">2. Processing your data</h4>
                        <h5 className="emp-h5 emp-font-semibold">What is the purpose of our processing?</h5>
                        <p className="pp-p">We process your data in order to provide a personalized burnout improvement program (and to support the delivery of that program).</p>
                        <h5 className="emp-h5 emp-font-semibold">What is our legal basis for processing?</h5>
                        <p className="pp-p">We require consent from all users before processing their data. This consent can be withdrawn at any time.</p>
                        <h5 className="emp-h5 emp-font-semibold">What data do we collect?</h5>
                        <h5 className="emp-h5 emp-font-semibold">Personal information</h5>
                        <p className="pp-p">
                            We collect and use information like your name, email address, and phone number to personalize the program and communicate with you. 
                            You’re able to opt out of any external communications (i.e., emails, phone calls, and SMS messages) at any time.
                        </p>
                        <p className="pp-p">
                            We use information such as your age and gender to assist our interpretation of your mood and behavior, as these can vary based on these variables.
                        </p>
                        <h6 className="emp-h6 emp-font-semibold">Electronic identifiers</h6>
                        <p className="pp-p">
                            We may collect information about the devices you use to access the System, including (but not limited to) IP address, mobile device UDID and IMEI numbers, 
                            operating system, browser type, and screen size. This information is used to provide you with customer support, for system administration, to tailor your 
                            experience of the System, to report aggregate information internally, and to assist communication (e.g., push notifications).
                        </p>
                        <h5 className="emp-h5 emp-font-semibold">Cookies</h5>
                        <p className="pp-p">
                            We may store cookies (small text files managed by your web browser) on your computer in order to improve your experience with the System. Example uses of 
                            these cookies include: recognizing you when you return to the System, maintaining data you’ve entered across multiple sessions, and storing information 
                            about your personal preferences.
                        </p>
                        <p className="pp-p">
                            You may refuse to accept cookies by changing the settings on your device to prevent cookies from being set. However, if you select this setting you may be 
                            unable to access certain parts of the System. Unless you have adjusted your browser setting so that it will refuse cookies, our system may issue cookies when you visit the System.
                        </p>
                        <h5 className="emp-h5 emp-font-semibold">Non-identifiable information</h5>
                        <p className="pp-p">
                            We may include your data in aggregated data sets shared with our research partners. In these sets, your data is not personally identifiable, and would be 
                            used for supporting generalized statements (e.g., “women under the age of 40 experience the highest degrees of burnout”).
                        </p>
                        <h4 className="emp-h4 emp-font-semibold">3. Who has access to that data?</h4>
                        <p className="pp-p">
                            Sunset Health understands that your information is private and personal and is dedicated to maintaining its confidentiality 
                            and integrity. As such, we will never sell or rent it, and we have policies, procedures, and other safeguards to help protect 
                            it from improper use and disclosure.
                        </p>
                        <p className="pp-p">
                            We follow a Minimum Necessary Access Policy so any required disclosure of your identifiable health information is minimized. 
                            The following categories describe the ways in which we use your identifiable health information and the rare instances that 
                            require us to disclose it to persons and entities outside of Sunset Health. We have not listed every use or disclosure within 
                            the categories below, but all permitted uses and disclosures will fall within one of the following categories. In addition, 
                            there are some uses and disclosures that may require your specific authorization.
                        </p>
                        <p className="pp-p">
                            Sunset Health does not disclose Personal Information to third parties for any purpose materially different from the purpose(s) 
                            for which it was originally collected.
                        </p>
                        <h5 className="emp-h5 emp-font-semibold">Disclosure at your request</h5>
                        <p className="pp-p">
                            We may disclose information relating to your use of the System when requested by you. This disclosure at your request may require written authorization by you.
                        </p>
                        <h5 className="emp-h5 emp-font-semibold">Payment</h5>
                        <p className="pp-p">
                            We do not store credit card or customer details with any 3rd parties except trusted suppliers who help us deliver the services 
                            associated with the System and we are committed to ensuring that all suppliers meet our security and data protection standards. 
                            As such, we may use and disclose your information to obtain payment for services that we provide to you.
                        </p>
                        <h5 className="emp-h5 emp-font-semibold">Services and Operations</h5>
                        <p className="pp-p">
                            We may use and disclose your identifiable health information in connection with providing services, for our internal operations, which include administration, 
                            eligibility, planning, analytics and various activities that assess and improve the quality and cost effectiveness of the service that we deliver to you. 
                            Examples are using information about you to improve the quality of the service, satisfaction surveys, customer services and internal training. To the extent 
                            you receive access to our Website and App through your employer, our services may include supporting, and sharing information with, your employer’s wellness 
                            program or third-party administrator or other similar programs. Possible information to be shared may include participation data (i.e. the fact that you used 
                            Sunset Health), milestone data (e.g. number of sessions you complete or how many tasks you accomplish) to allow you to earn incentives and rewards (if those 
                            are offered as part of your wellness program), as well as anonymized data from your initial burnout assessment. Information that identifies you as an individual 
                            will not be shared with your employer.
                        </p>
                        <h5 className="emp-h5 emp-font-semibold">Emails</h5>
                        <p className="pp-p">
                            We may receive a confirmation when you open an email from us, or click on a link in an email, if your computer supports this 
                            type of program. We use this confirmation to help us make emails more interesting and helpful. When you receive an email from us, 
                            you can opt out of receiving further emails by following the included instructions to unsubscribe. However, by opting out of 
                            further email communications after you sign up, you may limit program reminders and other valuable program content and components.
                        </p>
                        <h5 className="emp-h5 emp-font-semibold">Reminders and notifications</h5>
                        <p className="pp-p">
                            We may use and disclose your information to contact you as a reminder to interact with, or complete tasks relating to your use 
                            of the System. You may make changes to the format and frequency of these reminders, or cancel these reminders and/or notifications 
                            by logging into your Sunset Health account on the Website, and/or by accessing the native notification settings on your mobile device 
                            when using the App.
                        </p>
                        <h5 className="emp-h5 emp-font-semibold">Third party service providers</h5>
                        <p className="pp-p">
                            There are some services provided in our organization through third party services providers. Examples of third party services providers 
                            include accounting services, server hosting and email delivery providers, business associates, vendors and other business partners and 
                            reputable companies in the industry who subcontract to us or to those of your employer as our corporate customers, where permitted by law. 
                            We may disclose your identifiable health information to our third party services providers so that they can perform the job that is required 
                            of them. To protect your identifiable health information, we require appropriate contracts or written agreements be in place that safeguard 
                            your identifiable health information.Third party medical professionalsWith your explicit permission, we may share your identifiable health 
                            information with third party medical professionals nominated by you, e.g. through Sunset Health Clinic. You can revoke your permission at 
                            any time via your account page.
                        </p>
                        <h5 className="emp-h5 emp-font-semibold">Threat to health or safety</h5>
                        <p className="pp-p">
                            We may use and disclose your information when necessary to prevent a serious threat to your health and safety or the health and safety of 
                            the public or another person. Any disclosure, however, would only be to someone able to help prevent the threat.
                        </p>
                        <h5 className="emp-h5 emp-font-semibold">As required by law</h5>
                        <p className="pp-p">
                            Certain laws permit or require certain uses and disclosures of information for example, for public health activities, health oversight activities 
                            and law enforcement. In these instances, Sunset Health will only use or disclose your identifiable health information to the extent the law requires.
                        </p>
                        <h6 className="emp-h5 emp-font-semibold">Transfer of business assets</h6>
                        <p className="pp-p">
                            In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such 
                            business or assets. If Sunset Health or substantially all of its assets are acquired by a third party, personal data held by it about its customers will 
                            be one of the transferred assets. Sunset Health will ensure that information transferred to third parties will only be used in a way that is compliant 
                            with Privacy Shield Principles, and will remain liable in cases of onward transfers to third parties.
                        </p>
                        <h4 className="emp-h4 emp-font-semibold">4. How do we store your data?</h4>
                        <p className="pp-p">
                            Information you provide to us is stored in encrypted form on secure servers located in the US, which are owned and operated 
                            by Amazon Web Services (AWS). AWS are industry leaders in the provision of hosting services and take security very seriously – 
                            you can find out more about their security policies and processes in their Security Whitepapers: https://aws.amazon.com/security/security-resources/.
                        </p>
                        <h4 className="emp-h4 emp-font-semibold">5. Your rights</h4>
                        <p className="pp-p">Users of the System have certain specific rights with regard to their information.</p>
                        <h5 className="emp-h5 emp-font-semibold">Right to access</h5>
                        <p className="pp-p">
                            A user of the System has the right to view all personal information that Sunset Health has collected about them, as well as the disclosure of this data. 
                            In order to receive this data, please contact the Security, Privacy, and Compliance Officer. The first copy of this information is provided free of charge, 
                            and in a portable / common electronic form (e.g., CSV file).
                        </p>
                        <h5 className="emp-h5 emp-font-semibold">Right to accuracy</h5>
                        <p className="pp-p">
                            A user of the System has the right to ensure that the data we have stored is accurate. In most cases, the system allows you to directly modify your own 
                            information. However, if there is incorrect data within our system that you are not able to change, please contact the Security, Privacy, and Compliance 
                            Officer and we will work directly with you to update this information.
                        </p>
                        <h5 className="emp-h5 emp-font-semibold">Right to deletion</h5>
                        <p className="pp-p">
                            A user of the System has the right to request deletion of all data within the system. To request your data be deleted, please contact the Security, Privacy, 
                            and Compliance Officer. In most cases, this request will be completed within 30 days. If circumstances require a delay to this deletion, Sunset Health will 
                            notify you directly explaining the reason for the delay. Note also that in some cases, there may be a legal requirement to hold on to your data. Again, Sunset 
                            Health will notify you directly if this is the case.
                        </p>
                        <h5 className="emp-h5 emp-font-semibold">Right to withdraw consent</h5>
                        <p className="pp-p">
                            A user of the System has the right to withdraw their consent at any time by contacting the Security, Privacy, and Compliance Officer. Please note that without 
                            consent to process your data, we will be unable to deliver the Sunset Health program.
                        </p>
                        <h5 className="emp-h5 emp-font-semibold">Right to notification of disclosure</h5>
                        <p className="pp-p">
                            In addition to the right to request disclosures of your data specified in the “right to access” above, we will notify you as required by law if there has 
                            been a breach of the security of your identifiable health information.
                        </p>
                        <h5 className="emp-h5 emp-font-semibold">Concerns or complaints</h5>
                        <p className="pp-p">
                            If you believe that any of your rights with respect to your or others’ identifiable health information have been violated by us, our employees or agents, please 
                            communicate with the Sunset Health Security, Privacy, and Compliance Officer.
                        </p>
                        <h4 className="emp-h4 emp-font-semibold">6. Amending this Policy</h4>
                        <p className="pp-p">
                            We reserve the right to revise this Policy without notification. Any changes or updates will be effective immediately 
                            upon posting to https://sunset.health/pp. Your continued use of the System constitutes your agreement to abide by the 
                            Privacy Policy as changed. Under certain circumstances (for example, if we expand the ways in which we use your personal 
                            information beyond the uses stated in our Privacy Policy at the time of collection), we may also elect to notify you of 
                            changes or updates to our Privacy Policy by additional means, such as by sending you an email.Questions relating to revisions 
                            to this Policy may be addressed to the Security, Privacy, and Compliance Officer.
                        </p>
                        <h4 className="emp-h4 emp-font-semibold">7. Who can you contact?</h4>
                        <p className="pp-p"><span style={{"white-space": "pre-line"}}>Security, Privacy, and Compliance Officer</span><br/>Sunset Health’s Security, Privacy, and Compliance Officer (and Data Controller) can be reached at:</p>
                        <p className="pp-p">
                            <span style={{"white-space": "pre-line"}}>Attn: Security, Privacy, and Compliance</span><br/>
                            <span style={{"white-space": "pre-line"}}>132 North Crescent Heights Blvd</span><br/>
                            <span style={{"white-space": "pre-line"}}>Los Angeles, CA 90048</span><br/>
                            <span style={{"white-space": "pre-line"}}>USA</span>
                        </p>
                        <p className="pp-p">support@sunset.health</p>
                        <h4 className="emp-h4 emp-font-semibold">8. Effective Date</h4>
                        <p className="pp-p">This Policy is effective as of December 5th, 2019.</p>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default PrivacyPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`